import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-registrar-qr',
  templateUrl: './registrar-qr.component.html',
  styleUrls: ['./registrar-qr.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarQrComponent implements OnInit {
  public id:any = this.route.snapshot.paramMap.get('id');
  public accion:any = this.route.snapshot.paramMap.get('accion');
  public data:any = {pegatina_en_movil: 1, pais: 'España'};
  public dispositivo:any = {};
  public noencontrado:boolean = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Help ME');
    if (this.accion == 'pin') this.title.setTitle('Help ME - Establecer PIN');
    if (this.accion == 'validate-pin') this.title.setTitle('Help ME - Validar PIN');
    if (this.accion == 'edit') this.title.setTitle('Help ME - Edita los datos');
    // this.meta.updateTag({name: 'description', content: 'Repara tu móvil en menos de 60 minutos'});
    this.GetDispositivo();
    $.OpenPin = () => {
      this.accion = 'validar-pin';
      this.data = this.dispositivo;
      this.data.pin = '';
      this.appref.tick();
    };
  }
  GetDispositivo() {
    this.globals.Api('/pub-dispositivo', {id: this.id}).subscribe((data:any) => {
      if (!data || !Object.keys(data) || data.error) {
        this.dispositivo = null;
        if (data.value == 'No encontrado') this.noencontrado = true;
        return;
      }
      this.dispositivo = data;
      this.globals.dispositivo = this.dispositivo;
      if (!this.dispositivo.tiene_pin) {
        this.globals.dispositivo.accion = 'pin';
        this.accion = 'pin';
        this.title.setTitle('Help ME - Establecer PIN');
        return;
      }
      if (this.dispositivo.id_estado == 1) {
        if (!this.dispositivo.validar) {
          this.globals.dispositivo.accion = 'validar-pin';
          this.accion = 'validar-pin';
          this.title.setTitle('Help ME - Validar PIN');
          return;
        }
        this.globals.dispositivo.accion = 'edit';
        this.accion = 'edit';
        this.title.setTitle('Help ME - Edita los datos');
        return;
      }
    }, (error:any) => {
      //Reintentamos conexión
      setTimeout(() => { this.GetDispositivo(); }, 3000);
    });
  }
  EstablecerKeyPIN(event:any) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.target.blur();
      this.EstablecerPIN();
    }
  }
  EstablecerPIN() {
    if (!this.data.pin || this.data.pin.trim() == '') {
      swal('Introduzca PIN', 'Por favor, introduzca un PIN para continuar', 'error');return;
    }
    if (this.data.pin.length != 4 || isNaN(this.data.pin)) {
      swal('PIN no válido', 'Por favor, introduzca un PIN numérico de 4 dígitos para continuar', 'error');return;
    }
    $.buttonloading('.btn-establecer-pin', 'Enviando');
    this.globals.Api('/pub-dispositivo-set-pin', {id: this.id, pin: this.data.pin}).subscribe((data:any) => {
      $.buttoncancelloading('.btn-establecer-pin');
      if (!data || !Object.keys(data) || data.error) {
        toastr.error('Se ha producido un error', 'Error');
        return;
      }
      this.accion = 'edit';
      this.title.setTitle('Help ME - Edita los datos');
      this.appref.tick();
    }, (error:any) => {
      $.buttoncancelloading('.btn-establecer-pin');
    });
  }
  ValidarKeyPIN(event:any) {
    if (event.target.value.length == 4) {
      event.target.blur();
      this.ValidarPIN();
      return;
    }
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.target.blur();
      this.ValidarPIN();
    }
  }
  ValidarPIN() {
    if (!this.data.pin || this.data.pin.trim() == '') {
      swal('Introduzca PIN', 'Por favor, introduzca un PIN para continuar', 'error');return;
    }
    if (this.data.pin.length != 4 || isNaN(this.data.pin)) {
      swal('PIN no válido', 'Por favor, introduzca un PIN numérico de 4 dígitos para continuar', 'error');return;
    }
    $.buttonloading('.btn-validar-pin', 'Enviando');
    this.globals.Api('/pub-dispositivo-validar-pin', {id: this.id, pin: this.data.pin}).subscribe((data:any) => {
      $.buttoncancelloading('.btn-validar-pin');
      if (!data || !Object.keys(data) || data.error) {
        if (data.value == 'PIN Error') {
          toastr.error('El PIN introducido no es correcto', 'PIN Incorrecto');
          return;
        }
        toastr.error('Se ha producido un error', 'Error');
        return;
      }
      this.accion = 'edit';
      this.title.setTitle('Help ME - Edita los datos');
      this.appref.tick();
    }, (error:any) => {
      $.buttoncancelloading('.btn-validar-pin');
    });
  }
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }
  Guardar() {
    if (
         !this.data.nombre
      || !this.data.apellidos
      || !this.data.email
      || !this.data.telefono
      || !this.data.telefono_contacto
      || this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.email == ''
      || this.data.telefono == ''
      || this.data.telefono_contacto == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    if (!this.data.aceptapolitica) {
      swal('Política privacidad y condiciones', 'Debe marcar que ha leído y acepta las condiciones para continuar', 'error');return;
    }
    this.data.id = this.id;

    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/pub-dispositivo-edit', this.data).subscribe((data:any) => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || !Object.keys(data) || data.error) {
        toastr.error('Se ha producido un error', 'Error');
        return;
      }
      this.accion = 'confirmacion';
      this.title.setTitle('Help ME - Datos confirmados');
      this.appref.tick();
    }, (error:any) => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

}
