<header class="navbar navbar-inverse navbar-fixed-top navbar-admin">
    <ul class="nav navbar-nav-custom pull-left">
        <li class="animation-fadeInQuick">
            <div id="sidebar-brand">
                <a class="sidebar-title">
                    <img src="assets/isotipo.svg" alt="" class="m-r-10">
                    <!-- <span class="sidebar-nav-mini-hide">Help<strong>ME</strong></span> -->
                    <img src="assets/logo.svg" alt="" style="height:22px;">
                </a>
            </div>
        </li>
    </ul>
    <ul class="nav navbar-nav-custom pull-right">
        <li class="" *ngIf="globals.dispositivo && globals.dispositivo.id_estado == 2">
            <a class="cursor" (click)="EditarDatos()">
                <i class="fa fa-pencil m-r-5"></i> Editar mis datos
            </a>
        </li>
    </ul>
</header>