<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid();CargarGridImpresiones();"><i class="fa fa-refresh"></i></button>
                                <a class="btn btn-default btn-primary m-l-10" (click)="GenerarQRs()"><i class="fa fa-qrcode"></i> Generar QRs</a>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <ng-container *ngIf="GetTabActive() == '#tab-codigos-qr'">
                            <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                              <label class="col-form-label inline-block m-r-10">Mostrar</label>
                              <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                              </select>
                            </div>
                            <div class="col-lg-3 col-md-6 col-xs-12 my-auto text-center">
                              <div class="input-group width-100">
                                <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                                <div class="input-group-append cursor" (click)="Buscar()">
                                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="GetTabActive() == '#tab-impresiones'">
                            <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                              <label class="col-form-label inline-block m-r-10">Mostrar</label>
                              <select class="form-control inline-block" (change)="BuscarImpresiones()" [(ngModel)]="paramsimpresiones.page_size" style="width:auto;">
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                              </select>
                            </div>
                            <div class="col-lg-3 col-md-6 col-xs-12 my-auto text-center">
                              <div class="input-group width-100">
                                <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="BuscarImpresiones()" [(ngModel)]="paramsimpresiones.filtro">
                                <div class="input-group-append cursor" (click)="BuscarImpresiones()">
                                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                              <a class="btn btn-default btn-block" (click)="VerDescargadas()" *ngIf="!paramsimpresiones.archivadas"><i class="fa fa-download"></i> Ver descargadas</a>
                              <a class="btn btn-default btn-block wrap" (click)="VerDescargadas()" *ngIf="paramsimpresiones.archivadas">Ver impresiones sin descargar</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-impresiones"><i class="fa fa-print"></i> Impresiones</a></li>
                            <li><a href="#tab-codigos-qr"><i class="fa fa-qrcode"></i> Códigos QR <span *ngIf="params.total">({{params.total}} Total)</span></a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-impresiones">
                            <div class="table-responsive table-grid block full break-padding">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width:70px"></th>
                                            <th>Impresión
                                                <span *ngIf="!paramsimpresiones.archivadas">en cola</span>
                                                <span *ngIf="paramsimpresiones.archivadas">descargada</span>
                                            </th>
                                            <th style="width:90px">
                                              Descargar
                                              <a class="btn btn-primary btn-xs" (click)="DesgarcarPagina()">
                                                <i class="fa fa-download text-white"></i> Descargar página
                                              </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of impresiones" class="cursor {{item.descargado ? 'descargado' : null}}" (click)="DescargarEPS(item)">
                                            <td>
                                                <i class="fa fa-print"></i>
                                            </td>
                                            <td>
                                                <div class="font-bold text-primary">{{item.impresion}}
                                                  <i class="m-l-10 font-normal text-black font-12" *ngIf="item.descargado || paramsimpresiones.archivadas"><i class="fa fa-download"></i> Descargada</i>
                                                </div>
                                                
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-download text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="dataTables_paginate paging_simple_numbers m-r-10">
                                  <ul class="pagination justify-content-end">
                                    <li class="paginate_button page-item previous {{paramsimpresiones.page_index <= 1 ? 'disabled' : ''}}">
                                      <a (click)="PagingImpresiones('prev')" class="page-link">Anterior</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="paramsimpresiones.page_index > 1">
                                      <a (click)="PagingImpresiones('prev')" class="page-link">{{paramsimpresiones.page_index-1}}</a>
                                    </li>
                                    <li class="paginate_button page-item active">
                                      <a class="page-link cursor">{{paramsimpresiones.page_index}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="paramsimpresiones.page_index < paramsimpresiones.pages">
                                      <a (click)="PagingImpresiones('next')" class="page-link">{{paramsimpresiones.page_index+1}}</a>
                                    </li>
                                    <li class="paginate_button page-item next {{paramsimpresiones.page_index >= paramsimpresiones.pages ? 'disabled' : ''}}">
                                      <a (click)="PagingImpresiones('next')" class="page-link">Siguiente</a>
                                    </li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-codigos-qr">
                            <div class="table-responsive table-grid block full break-padding">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width:70px"></th>
                                            <th style="width:240px" order="qrid">Código</th>
                                            <th style="min-width:240px" order="qrlink">Link</th>
                                            <th style="min-width:240px" order="nombre">Dispositivo asociado</th>
                                            <!-- <th style="width:50px">Borrar</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data" class="cursor">
                                            <td>
                                                <a href="https://chart.apis.google.com/chart?cht=qr&chs=300x300&chld=M%7C0&chl={{globals.url}}/{{item.qrid}}" 
                                                    data-toggle="lightbox-image" title="QR {{item.qrid}}">
                                                    <img src="https://chart.apis.google.com/chart?cht=qr&chs=300x300&chld=M%7C0&chl={{globals.url}}/{{item.qrid}}" 
                                                        alt="QR" style="width:40px;height:40px;">
                                                </a>
                                            </td>
                                            <td>
                                                <div class="font-bold text-primary">{{item.qrid}}</div>
                                            </td>
                                            <td>
                                                <a target="_blank" href="{{globals.url}}/{{item.qrid}}">
                                                    {{globals.url}}/{{item.qrid}}
                                                </a>
                                            </td>
                                            <td>
                                                <div class="font-bold text-primary">{{item.nombre}} {{item.apellidos}}</div>
                                                <div class="text-ellipsis" style="max-width:240px;">{{item.dispositivo}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="dataTables_paginate paging_simple_numbers m-r-10">
                                  <ul class="pagination justify-content-end">
                                    <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                                      <a (click)="Paging('prev')" class="page-link">Anterior</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                                      <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                                    </li>
                                    <li class="paginate_button page-item active">
                                      <a class="page-link cursor">{{params.page_index}}</a>
                                    </li>
                                    <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                                      <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                                    </li>
                                    <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                                      <a (click)="Paging('next')" class="page-link">Siguiente</a>
                                    </li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
