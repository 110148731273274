<div id="page-wrapper">
    <div id="page-container" class="frontend header-fixed-top">
        <app-header-frontend></app-header-frontend>
        <div id="main-container">
            <div class="page-content">
                <ng-container *ngIf="noencontrado">
                  <div class="no-results">
                      <img src="assets/mobile-error.svg" alt="Dispositivo no encontrado">
                      <h4>ID de dispositivo no encontrado</h4>
                  </div>
                </ng-container>
                <ng-container *ngIf="accion == 'pin'">
                    
                  <div class="pin-text-container">
                    <h4>¿Te ayudamos a recuperar tu móvil?</h4>
                    <p class="text-left">
                      1. Invéntate un PIN numérico.<br>
                      2. Rellena el formulario y si quieres, ofrece una recompensa.<br>
                      3. Pon la pegatina en tu móvil.<br>
                      4. Si se pierde, se podrán en contacto contigo.
                    </p>
                  </div>
                  <div class="pin-container">
                      <div class="pin-header">
                          <img src="assets/pin-code.svg" alt="">
                          <h4>Invéntate un PIN numérico</h4>
                          <p>Crea un PIN numérico que puedas recordar a futuro</p>
                      </div>
                      <div class="form-group">
                          <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" [(ngModel)]="data.pin" 
                          maxlength="4" (keyup)="EstablecerKeyPIN($event)">
                      </div>
                      <p class="text-danger">Recuerda apuntar y guardar este PIN</p>
                      <a class="btn btn-primary btn-lg btn-establecer-pin" (click)="EstablecerPIN()">Establecer PIN</a>
                  </div>
                    
                </ng-container>
                <ng-container *ngIf="accion == 'validar-pin'">
                    
                  <div class="pin-text-container">
                    <h4>¿Te ayudamos a <br>recuperar tu móvil?</h4>
                    <p class="text-left">
                      1. Invéntate un PIN numérico.<br>
                      2. Rellena el formulario y si quieres, ofrece una recompensa.<br>
                      3. Pon la pegatina en tu móvil.<br>
                      4. Si se pierde, se podrán en contacto contigo.
                    </p>
                  </div>
                  <div class="pin-container">
                      <div class="pin-header">
                          <img src="assets/pin-code.svg" alt="">
                          <h4>Introduzca PIN</h4>
                          <p>Recuerda el PIN numérico que introduciste</p>
                      </div>
                      <div class="form-group">
                          <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" [(ngModel)]="data.pin" 
                            maxlength="4" (keyup)="ValidarKeyPIN($event)">
                      </div>
                      <a class="btn btn-primary btn-lg btn-validar-pin" (click)="ValidarPIN()">Validar</a>
                  </div>
                    
                </ng-container>
                <ng-container *ngIf="accion == 'edit'">
                  <div class="edit-container">
                    <h4>¿Vas a poner la pegatina en tu móvil?</h4>
                    <div class="form-group">
                      <label class="radio-inline" for="chk-pegatina1">
                        <input type="radio" id="chk-pegatina1" name="chks-pegatina" [value]="1" [(ngModel)]="data.pegatina_en_movil"> Sí
                      </label>
                      <label class="radio-inline" for="chk-pegatina2">
                        <input type="radio" id="chk-pegatina2" name="chks-pegatina" [value]="0" [(ngModel)]="data.pegatina_en_movil"> No
                      </label>
                    </div>
                    <h4>Recompensa <span class="text-danger">(Opcional)</span></h4>
                    <p>Para aumentar tus posibilidades de devolución del terminal, incorpora una recompensa monetaria.</p>
                    <div class="form-group">
                      <textarea class="form-control" rows="2" placeholder="Ejemplo: 10€" [(ngModel)]="data.recompensa"></textarea>
                    </div>
                    <h4>Datos de contacto</h4>
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4">
                          <div class="form-group">
                            <label>Nombre <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4">
                          <div class="form-group">
                            <label>Apellidos <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4">
                          <div class="form-group">
                            <label>Direcci&oacute;n</label>
                            <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                          <div class="form-group">
                            <label>C&oacute;digo postal</label>
                            <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                              (change)="DatosLocalidad()">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-5">
                          <div class="form-group">
                            <label>Localidad</label>
                            <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4">
                          <div class="form-group">
                            <label>Provincia</label>
                            <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>Tel&eacute;fono propio <span class="text-danger">*</span></label>
                            <p class="text-danger text-uppercase">Tu múmero de teléfono</p>
                            <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>Tel&eacute;fono persona contacto <span class="text-danger">*</span></label>
                            <p class="text-danger text-uppercase">Pon el n&uacute;mero de un familiar, amigo/a, ...</p>
                            <input type="tel" class="form-control" [(ngModel)]="data.telefono_contacto" maxlength="20">
                          </div>
                        </div>
                        <div class="col-xs-12">
                          <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80">
                          </div>
                        </div>
                    </div>
                    <h4>Información adicional <span class="text-danger">(Opcional)</span></h4>
                    <div class="form-group">
                      <textarea class="form-control" rows="2" placeholder="Ejemplo: En caso de no contestar, llamar a mi amigo Alberto al 6********. Muchas gracias." [(ngModel)]="data.info_adicional"></textarea>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" id="chk-politica" [(ngModel)]="data.aceptapolitica">
                      <label for="chk-politica" class="m-l-10" style="display:inline;">He leído y estoy de acuerdo con la <a [routerLink]="['/politica-privacidad']" target="_blank">política de privacidad</a>
                          y los <a [routerLink]="['/terminos-condiciones']" target="_blank">términos y conficiones de uso</a>.
                      </label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" id="chk-comunicacion-comercial" [(ngModel)]="data.comunicacion_comercial">
                      <label for="chk-comunicacion-comercial" class="m-l-10" style="display:inline;">Quiero recibir las <a [routerLink]="['/comunicacion-comercial']" target="_blank">últimas novedades y promociones exclusivas</a>.
                      </label>
                    </div>
                    <a class="btn btn-primary btn-lg btn-block btn-guardar" (click)="Guardar()">Guardar datos</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="accion == 'confirmacion'">
                  <div class="confirmacion-container">
                    <img src="assets/mobile-check.svg" alt="Dispositivo confirmado">
                    <h4>
                      <b>Hemos confirmado tu dispositivo y tus datos de contacto.</b>
                      <br><br>¡Gracias por usar nuestra App!
                    </h4>
                  </div>
                </ng-container>

                <!--DATOS CONFIRMADOS-->
                <ng-container *ngIf="(!accion || accion == '') && dispositivo">
                  <div class="dispositivo-container">
                    <div class="flex"><img src="assets/conversation.svg" alt="Llama al contacto"></div>
                    <h3><b>¡Hola! Has encontrado el dispositivo propiedad de <span class="text-primary">{{dispositivo.nombre}}</span></b></h3>
                    <p style="font-size:20px;" class="m-b-40">Ha debido extraviarlo 😔</p>
                    <p>Por favor, ponte en contacto para realizar la devolución.</p>
                    <ng-container *ngIf="dispositivo.recompensa">
                      <h4>Esta es la recompensa por tu devolución:</h4>
                      <p>{{dispositivo.recompensa}}</p>
                    </ng-container>
                    <ng-container *ngIf="dispositivo.info_adicional">
                      <h4>Información adicional:</h4>
                      <p>{{dispositivo.info_adicional}}</p>
                    </ng-container>
                    <ng-container *ngIf="dispositivo.telefono">
                      <h4>Teléfono de contacto:</h4>
                      <p><a class="breakall font-20" href="tel:{{dispositivo.telefono_contacto}}"><i class="fa fa-phone"></i> {{dispositivo.telefono_contacto}}</a></p>
                    </ng-container>
                    <ng-container *ngIf="dispositivo.email">
                      <h4>Email de contacto:</h4>
                      <p><a class="breakall font-20" href="mailto:{{dispositivo.email}}"><i class="fa fa-envelope"></i> {{dispositivo.email}}</a></p>
                    </ng-container>
                  </div>
                </ng-container>

            </div>
        </div>
    </div>
    <app-footer-frontend></app-footer-frontend>
</div>