<!-- Login Container -->
<div id="login-container">
    <!-- Login Header -->
    <h1 class="h2 text-light text-center push-top-bottom animation-pullDown sombra-texto">
        <img src="assets/isotipo.svg" class="logo">
        <img src="assets/logo.svg" alt="" style="height:22px;">
    </h1>
    <!-- END Login Header -->

    <!-- Login Block -->
    <div class="block login-container-block animation-fadeInQuick {{!recuperarclave ? '' : 'hidden'}}">
        
        <h2 class="text-uppercase">Panel de administración</h2>

        <!-- Login Form -->
        <div id="form-login" class="form-horizontal">
            <div class="form-group">
                <label for="login-email" class="col-xs-12">Email</label>
                <div class="col-xs-12">
                    <input type="text" id="txtEmail" name="login-email" class="form-control" [(ngModel)]="email" placeholder="Tu email ..." autocomplete="off">
                </div>
            </div>
            <div class="form-group">
                <label for="login-password" class="col-xs-12">Contrase&ntilde;a</label>
                <div class="col-xs-12">
                    <div class="input-group input-group-clave">
                        <input type="password" id="txtClave" name="login-password" class="form-control" [(ngModel)]="clave" placeholder="Contrase&ntilde;a" autocomplete="off">
                        <div class="input-group-append cursor" (click)="VerClave()">
                          <span class="input-group-addon"><i class="fa fa-eye"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group form-actions">
                <div class="col-md-7 col-sm-6">
                    <label class="csscheckbox csscheckbox-primary">
                        <a class="cursor font-bold" (click)="recuperarclave = true"><small>¿Has olvidado tu contrase&ntilde;a?</small></a>
                    </label>
                </div>
                <div class="col-md-5 col-sm-6 text-right">
                    <a id="btnAcceder" class="btn btn-effect-ripple btn-primary text-uppercase" (click)="Login()">Acceder <i class="fa fa-chevron-right m-l-10"></i></a>
                </div>
            </div>
        </div>
        <!-- END Login Form -->
    </div>
    <div class="block login-container-block animation-fadeInQuick {{recuperarclave ? '' : 'hidden'}}">
        <h2>Recuperaci&oacute;n contrase&ntilde;a</h2>
        <div id="form-login" class="form-horizontal">
            <p>Introduce tu correo y te enviaremos tu nueva contrase&ntilde;a</p>
            <div class="form-group">
                <div class="col-xs-12">
                    <input id="txtEmailRecuperar" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" required>
                </div>
            </div>
            <div class="text-center">
                <a id="btnRecuperarClave" class="btn btn-effect-ripple btn-primary btn-block cursor" (click)="RecuperarClave()">Enviar correo recuperaci&oacute;n</a><br>
                <button type="button" class="btn btn-secondary m-t-10 m-b-20" (click)="recuperarclave = false">Atr&aacute;s</button>
            </div>
        </div>
    </div>
    <!-- END Login Block -->
</div>
<!-- END Login Container -->