<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Textos Legales</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Textos Legales</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-sm-12 m-b-30">
                        <h3>T&eacute;rminos y condiciones de uso</h3>
                        <textarea id="txtavisolegal" type="text" class="form-control text-editor" placeholder="" [innerHTML]="data.terminos_condiciones" rows="20"></textarea>
                    </div>
                    <div class="col-xs-12 col-sm-12 m-b-30">
                        <h3>Pol&iacute;tica de privacidad</h3>
                        <textarea id="txtpoliticaprivacidad" type="text" class="form-control text-editor" placeholder="" [innerHTML]="data.politica_privacidad" rows="20"></textarea>
                    </div>
                    <div class="col-xs-12 col-sm-12 m-b-30">
                        <h3>Pol&iacute;tica de cookies</h3>
                        <textarea id="txtpoliticacookies" type="text" class="form-control text-editor" placeholder="" [innerHTML]="data.politica_cookies" rows="20"></textarea>
                    </div>
                    <div class="col-xs-12 col-sm-12 m-b-30">
                        <h3>Quiero recibir las últimas novedades y promociones exclusivas</h3>
                        <textarea id="txtcomunicacioncomercial" type="text" class="form-control text-editor" placeholder="" [innerHTML]="data.comunicacion_comercial" rows="20"></textarea>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>