<div id="sidebar">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <img src="assets/isotipo.svg" alt="" class="m-r-10">
            <!-- <span class="sidebar-nav-mini-hide">Help<strong>ME</strong></span> -->
            <img src="assets/logo.svg" alt="" style="height:22px;">
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ng-container *ngIf="globals && globals.me">
                <!-- ADMIN -->
                <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="desktop-hidden">
                            <a [routerLink]="['/']" target="_blank"><i class="icofont-eye-alt sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Ir a la web</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <!-- <li>
                            <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                        </li> -->
                        <li>
                            <a [routerLink]="['/generador-qr']" routerLinkActive="active"><i class="icofont-qr-code sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Generador QR</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/dispositivos']" routerLinkActive="active"><i class="icofont-ipod-touch sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Dispositivos Registrados</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="icofont-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a [routerLink]="['/textos-legales']" routerLinkActive="active"><i class="icofont-file-document sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Textos Legales</span></a>
                        </li>
                    </ul>
                </ng-container>
                
                <!-- ADMINISTRATIVO -->
                <ng-container *ngIf="globals.me.id_rol == 2">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div id="sidebar-extra-info" class="sidebar-content sidebar-nav-mini-hide">
        <!--
        <div class="push-bit">
            <span class="pull-right">
                <a href="javascript:void(0)" class="text-muted"><i class="fa fa-plus"></i></a>
            </span>
            <small><strong>78 GB</strong> / 100 GB</small>
        </div>
        <div class="progress progress-mini push-bit">
            <div class="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%"></div>
        </div>
        -->
        <div class="font-12 text-muted">
            v.{{globals.version}}
        </div>
        <!-- <div class="text-center">
            <small>Creado por <a href="https://pecesgordos.es/" target="_blank">Peces Gordos Estudio</a></small><br>
        </div> -->
    </div>
    <!-- END Sidebar Extra Info -->
</div>