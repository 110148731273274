import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-header-frontend',
  templateUrl: './header-frontend.component.html'
})
export class HeaderFrontendComponent implements OnInit {
  public config:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    let page = $('#page-container');
    $('.frontend .sidebar-frontend a').on('click', () => {
      $(document).off('mouseup');
      page.toggleClass('sidebar-visible-xs');
    });
    this.globals.Api('/configuracion-public').subscribe((data:any) => {
      if (!data || data.error) return;
      this.config = data;
    });
  }
  OpenMenuMovil() {
    let page = $('#page-container');
    page.toggleClass('sidebar-visible-xs');
    $(document).off('mouseup').mouseup(function(e) {
      let page = $('#page-container');
      let container = $(".sidebar-frontend");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(document).off('mouseup');
        page.toggleClass('sidebar-visible-xs');
      }
    });
  }
  EditarDatos() {
    if ($.OpenPin) $.OpenPin();
  }
}
