import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { LoginComponent } from './backend/login/login.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { IndexComponent } from './frontend/index/index.component';
import { PaginaComponent } from './frontend/pagina/pagina.component';
import { TextoLegalComponent } from './frontend/texto-legal/texto-legal.component';
import { DispositivosComponent } from './backend/dispositivos/dispositivos.component';
import { GeneradorQrComponent } from './backend/generador-qr/generador-qr.component';
import { RegistrarQrComponent } from './frontend/registrar-qr/registrar-qr.component';
import { TextosLegalesComponent } from './backend/textos-legales/textos-legales.component';

const routes: Routes = [
  {path: '', redirectTo: 'no-device', pathMatch: 'full'},

  //BackEnd
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  // {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'dispositivos', component: DispositivosComponent},
  {path: 'generador-qr', component: GeneradorQrComponent},
  {path: 'textos-legales', component: TextosLegalesComponent},
  //FrontEnd
  {path: 'terminos-condiciones', component: TextoLegalComponent},
  {path: 'politica-privacidad', component: TextoLegalComponent},
  {path: 'politica-cookies', component: TextoLegalComponent},
  {path: 'comunicacion-comercial', component: TextoLegalComponent},
  {path: ':id', component: RegistrarQrComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
