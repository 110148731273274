<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                
                <!-- ADMIN -->
                <ng-container *ngIf="(globals && globals.me) && (globals.me.id_rol == 0 || globals.me.id_rol == 1)">

                    <div class="row">
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/dispositivos']" class="widget">
                                <div class="widget-content widget-content-mini bg-03">
                                    <span class="pull-right text-white">{{data.total_dispositivos_confirmados}}</span>
                                    <strong class="text-white">Disp. con pegatina</strong>
                                </div>
                                <div class="widget-content text-right clearfix">
                                    <div class="widget-icon bg-03 pull-left">
                                        <i class="icofont-stock-mobile text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 font-50 text-03"><strong>{{data.total_dispositivos}}</strong></h2>
                                    <span class="text-muted">Dispositivos</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/generador-qr']" class="widget">
                                <div class="widget-content widget-content-mini bg-04">
                                    <span class="pull-right text-white"></span>
                                    <strong class="text-white">Códigos QR</strong>
                                </div>
                                <div class="widget-content text-right clearfix">
                                    <div class="widget-icon bg-04 pull-left">
                                        <i class="icofont-qr-code text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 font-50 text-04"><strong>{{data.total_codigos_qr}}</strong></h2>
                                    <span class="text-muted">Códigos QR</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <a [routerLink]="['/usuarios']" class="widget">
                                <div class="widget-content widget-content-mini bg-01">
                                    <span class="pull-right text-white"></span>
                                    <strong class="text-white">Usuarios</strong>
                                </div>
                                <div class="widget-content text-right clearfix">
                                    <div class="widget-icon bg-01 pull-left">
                                        <i class="icofont-users-alt-3 text-light-op"></i>
                                    </div>
                                    <h2 class="widget-heading h3 font-50 text-01"><strong>{{data.total_usuarios}}</strong></h2>
                                    <span class="text-muted">Usuarios</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <canvas id="chart" style="height:340px;"></canvas>
                        </div>
                    </div>

                </ng-container>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>