import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-footer-frontend',
  templateUrl: './footer-frontend.component.html'
})
export class FooterFrontendComponent implements OnInit {
  public config:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $(document).ready(() => {
      this.ComprobarCookies();
    });
    this.globals.Api('/configuracion-public').subscribe((data:any) => {
      if (!data || data.error) return;
      this.config = data;
    });
  }

  ComprobarCookies() {
    if(localStorage.aceptacookies == 'true') {
      $('.aceptacion-politica-cookies').css('display', 'none');
    }
  }
  AceptarCookies() {
    localStorage.aceptacookies = 'true';
    $('.aceptacion-politica-cookies').css('display', 'none');
  }

}
