import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let Chart: any;

@Component({
  selector: 'app-backend-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  public reparaciones = [];
  public params:any = {};
  public data:any = {};

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $('body').removeClass('bg-body-login');
    this.title.setTitle('Help ME - Admin');
    this.meta.updateTag({name: 'description', content: 'Panel de administración'});
    App.init();
    // this.globals.Api('/datos-generales').subscribe(data => {
    //   if (!data) return;
    //   this.data = data;
    // });
    this.globals.Api('/me').subscribe(data => {
      if (!data || data.error || !data.acceso) return;
      let userdata = data;
      this.globals.me = userdata;
      localStorage.setItem('userdata', JSON.stringify(userdata));
    });
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
      setTimeout(() => {
        this.CargarChart();
      }, 300);
    });
  }

  CargarChart() {
    if (!this.data.meses || !this.data.meses.length) return;
    let labels:any = [];
    let dataset1:any = [];
    let dataset2:any = [];
    let dataset3:any = [];
    for (let item of this.data.meses) labels.push(`${item.mes} - ${item.anio}`);
    for (let item of this.data.meses) dataset1.push(item.dispositivos);
    for (let item of this.data.meses) dataset2.push(item.confirmados);
    for (let item of this.data.meses) dataset3.push(item.no_confirmados);
    const data = {
      labels: labels,
      datasets: [{
        label: 'Dispositivos Registrados',
        data: dataset1,
        backgroundColor: [
          '#2F3E46'
        ],
        borderColor: [
          '#2F3E46'
        ],
        borderWidth: 1
      },{
        label: 'Dispositivos Confirmados',
        data: dataset2,
        backgroundColor: [
          '#0DA853'
        ],
        borderColor: [
          '#0DA853'
        ],
        borderWidth: 1
      },{
        label: 'Dispositivos No Confirmados',
        data: dataset3,
        backgroundColor: [
          '#db2e11'
        ],
        borderColor: [
          '#db2e11'
        ],
        borderWidth: 1
      }]
    };
    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {display: true},
          tooltip: {
            callbacks: {
              label: function(tooltipItem, data) {
                return tooltipItem.formattedValue;
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
    };
    let ctx = document.getElementById('chart');
    new Chart(ctx, config);
  }

}
