import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let XLSX: any;
declare let saveAs: any;
declare let ace: any;

@Component({
  selector: 'app-config-app',
  templateUrl: './config-app.component.html'
})
export class ConfigAppComponent implements OnInit {
  public data:any = {
    customcss: '',
    emailconf_host: '',
    emailconf_port: '',
    emailconf_ssl: 0,
    emailconf_email: '',
    emailconf_pass: '',
    emailconf_alias: '',
    emailconf_emailadmin_alias: '',
    emailconf_emailadmin1: '',
    emailconf_emailadmin2: '',
    emailconf_emailadmin3: '',
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.data = this.globals.config;
    if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/configuracion').subscribe(data => {
      $.LoadingOverlay("hide");
      if (!data || data.error) return;
      this.globals.config = data;
      this.data = this.globals.config;
      if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.appref.tick();
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    }, error => {
      // toastr.error(`No se ha podido cargar datos de configuración por falta de conexión`, 'Error!');
      $.LoadingOverlay("hide");
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    });
    if (!this.data.emailconf_ssl) this.data.emailconf_ssl = 0;
    $.logseditor = ace.edit("txtlogsdb");
    $.logseditor.setTheme("ace/theme/monokai");
    this.globals.Api('/logs-db').subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      $.logseditor.setValue(data.content);
    });
  }
  
  Guardar() {
    if ($.csseditor) this.data.customcss = $.csseditor.getValue();
    this.data.customcss = this.data.customcss.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>?/gm, '');
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/configuracion-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.globals.config = this.data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      if ($.CargarConfiguracionGlobal) $.CargarConfiguracionGlobal();
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

  EmailTest() {
    this.globals.Api('/email-test').subscribe(data => {
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  ExportarDB() {
    this.globals.Api('/exportar-db', {}).subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     'data:text/plain;charset=utf-8,'+data.content);
      downloadAnchorNode.setAttribute("download", "database.sql");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
  BorrarLogs() {
    this.globals.Api('/borrar-logs-db').subscribe(data => {
      $.logseditor.setValue('');
    });
  }

}
