import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

@Component({
  selector: 'app-texto-legal',
  templateUrl: './texto-legal.component.html'
})
export class TextoLegalComponent implements OnInit {
  public texto:string = '';
  public data:any = {};

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) {}

  ngOnInit(): void {
    let path = window.location.pathname;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (this.globals.GetData(path)) this.texto = this.globals.GetData(path);
    if (this.globals.GetData(path+'-title')) this.title.setTitle(this.globals.GetData(path+'-title'));
    this.globals.Api('/configuracion-public').subscribe(data => {
      if (!data || data.error) return;
      this.data = data;
      if (path.indexOf('/terminos-condiciones') != -1) {
        this.title.setTitle('Términos y condiciones de uso');
        this.texto = this.data.terminos_condiciones;
      }
      if (path.indexOf('/politica-privacidad') != -1) {
        this.title.setTitle('Política de privacidad');
        this.texto = this.data.politica_privacidad;
      }
      if (path.indexOf('/politica-cookies') != -1) {
        this.title.setTitle('Política de cookies');
        this.texto = this.data.politica_cookies;
      }
      if (path.indexOf('/comunicacion-comercial') != -1) {
        this.title.setTitle('Quiero recibir las últimas novedades y promociones exclusivas');
        this.texto = this.data.comunicacion_comercial;
      }
      this.globals.SaveData(this.data, path);
      this.globals.SaveData(this.title.getTitle(), path+'-title');
      this.appref.tick();
    });
  }

}
