<!--
<div class="footer">
    <div class="text-right">
        Desarrollado por <a href="https://pecesgordos.es/" target="_blank">Peces Gordos Estudio</a>
    </div>
</div>
-->
<div class="aceptacion-politica-cookies">
    <p>
        <button (click)="AceptarCookies()" class="pull-right"><i class="fa fa-times"></i> Aceptar y cerrar éste mensaje</button>
        Éste sitio web usa cookies, si permanece aquí acepta su uso.
        Puede leer más sobre el uso de cookies en nuestra <a [routerLink]="['/politica-cookies']">pol&iacute;tica de cookies</a>.
    </p>
</div>