import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;

@Component({
  selector: 'app-textos-legales',
  templateUrl: './textos-legales.component.html'
})
export class TextosLegalesComponent implements OnInit {
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public sanitizer: DomSanitizer,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(2); //No administrativos
    $.checkrolredirect(3); //No técnicos
    $.checkrolredirect(4); //No clientes
    tinymce.remove();
    this.globals.Api('/configuracion').subscribe((data:any) => {
      if (!data) {
        tinymce.init({
          selector:'.text-editor',
          height: 450,
          plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
          language: 'es',
          content_style: `
            body {
              font-size:14px;
              font-family: 'Roboto';
            }
            body h1 {
              font-size: 22px;
              font-weight: bold;
              color: #00883D;
            }
            body h2 {
              font-size: 18px;
            }
          `
        });
        return;
      }
      this.data = data;
      tinymce.init({
        selector:'.text-editor',
        height: 450,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        language: 'es',
        content_style: `
          body {
            font-size:14px;
            font-family: 'Roboto';
          }
          body h1 {
            font-size: 22px;
            font-weight: bold;
            color: #00883D;
          }
          body h2 {
            font-size: 18px;
          }
        `
      });
      setTimeout(() => {$('.tox-notifications-container').hide();}, 1000);
      setTimeout(() => {$('.tox-notifications-container').hide();}, 2000);
      setTimeout(() => {$('.tox-notifications-container').hide();}, 3000);
    });
  }

  Guardar() {
    this.data.terminos_condiciones = tinymce.EditorManager.get('txtavisolegal').getContent();
    this.data.politica_privacidad = tinymce.EditorManager.get('txtpoliticaprivacidad').getContent();
    this.data.politica_cookies = tinymce.EditorManager.get('txtpoliticacookies').getContent();
    this.data.comunicacion_comercial = tinymce.EditorManager.get('txtcomunicacioncomercial').getContent();
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/configuracion-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }
}
