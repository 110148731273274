<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración App</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-correo"><i class="icofont-envelope"></i> Correo</a></li>
                            <!-- <li><a href="#tab-personalizacion"><i class="icofont-paint"></i> Personalizaci&oacute;n</a></li> -->
                            <li><a href="#tab-db"><i class="icofont-database"></i> Base de datos</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-correo">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo administrador</h2></div>
                                        <div class="form-group">
                                            <label>Alias email administrador</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_emailadmin_alias" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 1</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin1" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 2</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin2" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 3</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin3" maxlength="80">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-personalizacion">
                            <label>CSS Personalizado</label>
                            <div id="txtcustomcss" [innerHTML]="data.customcss" style="height:500px;"></div>
                        </div>
                        <div class="tab-pane" id="tab-db">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Exportar Base Datos</h2></div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="ExportarDB()"><i class="icofont-database"></i> Exportar</a>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <label>Logs DB <a class="btn btn-default" (click)="BorrarLogs()"><i class="fa fa-trash"></i></a></label>
                            <div id="txtlogsdb" style="height:300px;"></div>
                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>