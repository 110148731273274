<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive table-grid block full break-padding">
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <th style="min-width:190px" order="dispositivo">Dispositivo</th>
                              <th style="width:240px" order="nombre">Contacto</th>
                              <th style="width:100px" order="alta">Alta</th>
                              <th style="width:100px" order="estado">Estado</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" class="cursor">
                              <td (click)="Ver(item)">
                                <!-- <div class="font-bold text-primary">{{item.dispositivo}}</div> -->
                                <div>
                                  <span class="m-r-20"><i class="fa fa-mobile-phone"></i> {{item.sistema}}</span>
                                  <span><i class="fa fa-globe"></i> {{item.navegador}}</span>
                                </div>
                                <div class="font-bold">{{item.qrid}}</div>
                                <a [href]="item.qrlink" target="_blank" *ngIf="item.qrlink" class="font-11 breakall display-block" style="line-height: 1.1;"><i class="fa fa-link"></i> {{item.qrlink}}</a>
                              </td>
                              <td class="breaktd" (click)="Ver(item)">
                                <div class="font-bold text-primary">{{item.nombre}} {{item.apellidos}}</div>
                                <div *ngIf="item.localidad" class="font-11 breakall" style="line-height: 1.1;"><i class="fa fa-map-marker"></i> {{item.localidad}}</div>
                                <div *ngIf="item.telefono_contacto" class="font-11 breakall" style="line-height: 1.1;text-transform:lowercase;"><i class="fa fa-phone"></i> {{item.telefono_contacto}}</div>
                                <div *ngIf="item.email" class="font-11 breakall" style="line-height: 1.1;text-transform:lowercase;"><i class="fa fa-envelope"></i> {{item.email}}</div>
                              </td>
                              <td><span class="font-12">{{item.alta_format}}</span></td>
                              <td>
                                <span class="font-12" *ngIf="item.id_estado == 1">{{item.estado}}</span>
                                <span class="font-12 text-primary" *ngIf="item.id_estado == 2">{{item.estado}}</span>
                              </td>
                              <td class="text-center btntd">
                                <ng-container *ngIf="globals.me.id_rol == 0">
                                  <a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a>
                                </ng-container>
                                <ng-container *ngIf="globals.me.id_rol != 0 && item.id_estado != 2">
                                  <a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a>
                                </ng-container>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-dispositivo" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog {{dataitem.nombre ? 'modal-lg' : null}}">
        <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
              <h4 class="modal-title">{{dataitem.title}}</h4>
          </div>
          <div class="modal-body" *ngIf="dataitem">
            <div class="row">
              <div class="col-xs-12 {{dataitem.nombre ? 'col-md-6' : null}}">
                <h4>Datos del dispositivo</h4>
                <ul class="list-group">
                  <li class="list-group-item">Estado:
                    <span *ngIf="dataitem.id_estado == 1">{{dataitem.estado}}</span>
                    <span class="text-primary" *ngIf="dataitem.id_estado == 2">{{dataitem.estado}}</span>
                  </li>
                  <li class="list-group-item font-11">{{dataitem.dispositivo}}</li>
                  <li class="list-group-item">{{dataitem.sistema}} {{dataitem.navegador}}</li>
                  <li class="list-group-item">{{dataitem.qrid}}</li>
                  <li class="list-group-item"><a [href]="dataitem.qrlink" target="_blank" *ngIf="dataitem.qrlink" class="breakall"><i class="fa fa-link"></i> {{dataitem.qrlink}}</a></li>
                  <li class="list-group-item">PIN: {{dataitem.pin}}</li>
                </ul>
              </div>
              <ng-container *ngIf="dataitem.nombre">
              <div class="col-xs-12 col-md-6">
                  <h4>Información</h4>
                  <ul class="list-group">
                    <li class="list-group-item">¿Vas a poner la pegatina en tu móvil?
                      <span *ngIf="dataitem.pegatina_en_movil">Sí</span>
                      <span *ngIf="!dataitem.pegatina_en_movil">No</span>
                    </li>
                    <li class="list-group-item" *ngIf="dataitem.recompensa">Recompensa:<br>{{dataitem.recompensa}}</li>
                    <li class="list-group-item" *ngIf="dataitem.info_adicional">Info adicional:<br>{{dataitem.info_adicional}}</li>
                  </ul>
                  <h4>Datos de contacto</h4>
                  <ul class="list-group">
                    <li class="list-group-item">{{dataitem.nombre}} {{dataitem.apellidos}}</li>
                    <li class="list-group-item">{{dataitem.direccion}} {{dataitem.codpostal}} {{dataitem.localidad}} {{dataitem.provincia}}</li>
                    <li class="list-group-item"><a href="mailto:{{dataitem.email}}" *ngIf="dataitem.email" class="breakall"><i class="fa fa-envelope"></i> {{dataitem.email}}</a></li>
                    <li class="list-group-item"><a href="tel:{{dataitem.telefono_contacto}}" *ngIf="dataitem.telefono_contacto" class="breakall"><i class="fa fa-phone"></i> {{dataitem.telefono_contacto}}</a></li>
                    <ng-container *ngIf="dataitem.telefono">
                      <li class="list-group-item">Tlf. propio: <a href="tel:{{dataitem.telefono}}" class="breakall"><i class="fa fa-phone"></i> {{dataitem.telefono}}</a></li>
                    </ng-container>
                  </ul>
              </div>
              </ng-container>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
    </div>
</div>