import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { MenuComponent } from './backend/controls/menu/menu.component';
import { HeaderComponent } from './backend/controls/header/header.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { IndexComponent } from './frontend/index/index.component';
import { HeaderFrontendComponent } from './frontend/controls/header-frontend/header-frontend.component';
import { FooterFrontendComponent } from './frontend/controls/footer-frontend/footer-frontend.component';
import { ModalReparacionComponent } from './backend/controls/modal-reparacion/modal-reparacion.component';
import { SeleccionProductoComponent } from './backend/controls/seleccion-producto/seleccion-producto.component';
import { SeleccionTecnicoComponent } from './backend/controls/seleccion-tecnico/seleccion-tecnico.component';
import { TextoLegalComponent } from './frontend/texto-legal/texto-legal.component';
import { PaginaComponent } from './frontend/pagina/pagina.component';
import { ProcesoReparacionComponent } from './backend/controls/proceso-reparacion/proceso-reparacion.component';
import { StockComponent } from './backend/controls/stock/stock.component';
import { DispositivosComponent } from './backend/dispositivos/dispositivos.component';
import { GeneradorQrComponent } from './backend/generador-qr/generador-qr.component';
import { RegistrarQrComponent } from './frontend/registrar-qr/registrar-qr.component';
import { TextosLegalesComponent } from './backend/textos-legales/textos-legales.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    ConfiguracionComponent,
    MiPerfilComponent,
    ConfigAppComponent,
    IndexComponent,
    HeaderFrontendComponent,
    FooterFrontendComponent,
    ModalReparacionComponent,
    SeleccionProductoComponent,
    SeleccionTecnicoComponent,
    TextoLegalComponent,
    PaginaComponent,
    ProcesoReparacionComponent,
    StockComponent,
    DispositivosComponent,
    GeneradorQrComponent,
    RegistrarQrComponent,
    TextosLegalesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
