import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from './classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  
  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) {
    $.buttonloading = (id, text = '', clase = 'text-light') => {
      let selector = `#${id}`;
      if (id.indexOf('.') != -1) selector = `${id}`;
      $(selector)[0].originaltext = $(selector)[0].innerHTML;
      $(selector).html(`
        ${text}
        <div class="spinner-grow spinner-grow-sm middle ${clase}" role="status"></div>
      `);
      $(selector).attr('disabled', true);
      $(selector).css('pointer-events', 'none');
      $(selector).css('cursor', 'not-allowed');
    };
    $.buttoncancelloading = id => {
      let selector = `#${id}`;
      if (id.indexOf('.') != -1) selector = `${id}`;
      $(selector).html($(selector)[0].originaltext);
      $(selector).removeAttr('disabled');
      $(selector).css('pointer-events', 'all');
      $(selector).css('cursor', 'pointer');
    };
    $.disableinputnumberwheel = () => {
      $(document).on("wheel", "input[type=number]", function (e) {
        $(this).blur();
      });
    };
    $.fn.datepicker.dates['es'] = {
      days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: "Hoy",
      monthsTitle: "Meses",
      clear: "Borrar",
      weekStart: 1,
      format: "dd/mm/yyyy"
    };
    $.checkrolredirect = (id_rol:any) => {
      if (this.globals.me.id_rol == id_rol) {
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
      }
    };
    $.checkpermission = () => {
      if (!localStorage.getItem('token')) {
        this.ngZone.run(() => this.router.navigateByUrl('/admincontrol')).then();
      }
    };

    $.Logout = () => {
      swal({
        title: "¿Deseas cerrar sesión?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-warning",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        closeOnConfirm: true
      },() => {
        this.globals.Api('/del-webtoken').subscribe(data => {}, error => {});
        localStorage.removeItem('token');
        localStorage.removeItem('userdata');
        localStorage.clear();
        this.globals.token = '';
        this.globals.me = {};
        this.ngZone.run(() => this.router.navigateByUrl('/admincontrol')).then();
      });
    };

    //GOOGLE ANALYTICS
    // this.router.events.subscribe((val:any) => {
    //   if (val instanceof NavigationEnd) {
    //     if (
    //       val.urlAfterRedirects == '/'
    //       || val.urlAfterRedirects.toString().startsWith('/reparacion')
    //       || val.urlAfterRedirects.toString().startsWith('/compra-finalizada')
    //       || val.urlAfterRedirects.toString().startsWith('/compra-cancelada')
    //       || val.urlAfterRedirects.toString().startsWith('/compra-error')
    //       || val.urlAfterRedirects.toString().startsWith('/soluciones-empresas')
    //       || val.urlAfterRedirects.toString().startsWith('/como-funciona')
    //       || val.urlAfterRedirects.toString().startsWith('/reparacion-modelo')
    //       || val.urlAfterRedirects.toString().startsWith('/reparacion-problema')
    //       || val.urlAfterRedirects.toString().startsWith('/consultar-precios')
    //       ) {
    //         gtag('event', 'page_view', {
    //           page_path: val.urlAfterRedirects
    //         });
    //     }
    //     if (
    //       val.urlAfterRedirects == '/'
    //     ) {
    //       let script = document.createElement("script");
    //       script.id = 'tidio-chat-script';
    //       script.src = '//code.tidio.co/txzwjvrlwchpm4ika74tobdqskdb8ezx.js';
    //       script.async = true;
    //       document.body.append(script);
    //     } else {
    //       $('#tidio-chat-script').remove();
    //       $('#tidio-chat-code').remove();
    //       $('#tidio-chat').remove();
    //     }
    //   }
    // });
    
    if (localStorage.getItem('token') != null) {
      this.globals.token = localStorage.getItem('token');
      if (localStorage.getItem('userdata') != null) this.globals.me = JSON.parse(localStorage.getItem('userdata'));
      this.globals.Api('/me').subscribe(data => {
        if (!data || data.error || !data.acceso) {
          // this.ngZone.run(() => this.router.navigateByUrl('/admincontrol')).then();
          return;
        }
        let userdata = data;
        this.globals.me = userdata;
        if ($.MeDataCompleted) $.MeDataCompleted();
        localStorage.setItem('userdata', JSON.stringify(userdata));
        if (this.router.url == '/admincontrol') {
          // this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
        }
      }, error => {
        // this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
      });
    } else {
      // this.ngZone.run(() => this.router.navigateByUrl('/admincontrol')).then();
    }
  }
}
