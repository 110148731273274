import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;

@Component({
  selector: 'app-generador-qr',
  templateUrl: './generador-qr.component.html'
})
export class GeneradorQrComponent implements OnInit {
  public title:string = 'Códigos QR';
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  public impresiones:any = [];
  public paramsimpresiones:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(4); //No clientes
    this.CargarGrid();
    this.CargarGridImpresiones();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
  }

  GetTabActive() {
    return $('.nav-tabs li.active a').attr('href');
  }
  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/codigos-qr', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      setTimeout(() => {
        $('[data-toggle="lightbox-image"]').magnificPopup({type: 'image', image: {titleSrc: 'title'}});
      }, 200);
      if (!paginationload) return;
      this.globals.Api('/codigos-qr/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  GenerarQRs() {
    swal({
      title: "¿Generar QRs?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.2)',
        imageResizeFactor: 0.5,
        text: 'Generando QRs ...'
      });
      this.globals.Api('/generar-qr').subscribe((data:any) => {
        $.LoadingOverlay("hide", true);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Generados ${data.cantidad} QRs.`, 'QRs generados');
        this.Buscar();
        this.CargarGridImpresiones();
      }, (error:any) => {
        $.LoadingOverlay("hide", true);
        swal('Error', 'Se ha producido un error', 'error');return;
      });
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar código QR?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/codigos-qr-del', {id: item.id}).subscribe((data:any) => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex((el:any) => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  //Impresiones
  CargarGridImpresiones(paginationload:boolean = true) {
    if (this.globals.GetData()) this.impresiones = this.globals.GetData();
    this.globals.Api('/impresiones', this.paramsimpresiones).subscribe(data => {
      if (!data || !data.length) {
        this.impresiones = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.impresiones = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/impresiones/total', this.paramsimpresiones).subscribe(data => {
        if (!data || !data.length) return;
        this.paramsimpresiones.total = data[0].total;
        this.paramsimpresiones.pages = Math.ceil(this.paramsimpresiones.total / this.paramsimpresiones.page_size);
      });
    });
  }
  PagingImpresiones(dir, table = '') {
    if (dir == 'prev') {
      this.paramsimpresiones.page_index -= 1;
    }
    if (dir == 'next') {
      this.paramsimpresiones.page_index += 1;
    }
    this.CargarGridImpresiones(false);
  }
  BuscarImpresiones() {
    this.paramsimpresiones.page_index = 1;
    this.CargarGridImpresiones();
  }
  DescargarEPS(item:any) {
    if (item.descargado) return;
    let refimpresion = item.refimpresion.toString().padStart(6, '0');
    toastr.success('Generando documento ' + refimpresion, 'Impresión');
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.2)',
    //   imageResizeFactor: 0.5,
    //   text: 'Generando documento de impresión ...'
    // });

    /*
    this.globals.Api('/generar-impresion', {refimpresion: item.refimpresion}).subscribe(data => {
      if (!data || !data.url) {
        toastr.error('Lo sentimos, se ha producido un error', 'Error');
        return;
      }
      $.LoadingOverlay("hide", true);
      item.descargado = true;
      let a:any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = data.url;
      a.download = item.impresion + '.eps';
      a.click();
    }, error => {
      $.LoadingOverlay("hide", true);
      toastr.error('Lo sentimos, se ha producido un error', 'Error');
      this.data = null;
    });
    */
    
    
    this.globals.Api('/codigos-qr', {refimpresion: item.refimpresion}).subscribe((data:any) => {
      if (!data || !data.length) {
        $.LoadingOverlay("hide", true);
        toastr.error('Lo sentimos, se ha producido un error', 'Error');
        return;
      }

      $.get('/assets/plancha-qr.svg', function(response) {
        let svg = $(response).find('svg');

        let index = 0;
        for (let item of data) {
          let svgimg = document.createElementNS('http://www.w3.org/2000/svg','image');
          svgimg.setAttributeNS(null, 'height', '55');
          svgimg.setAttributeNS(null, 'width', '55');
          svgimg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', item.imagenqrblob);
          // if (item.posicion) svgimg.setAttributeNS(null, 'x', item.posicion.x);
          // if (item.posicion) svgimg.setAttributeNS(null, 'y', item.posicion.y);
          let x = $($(svg).find('circle.cls-1')[index]).attr('cx');
          let y = $($(svg).find('circle.cls-1')[index]).attr('cy');
          if (item.posicion) svgimg.setAttributeNS(null, 'x', (parseInt(x)-26).toString());
          if (item.posicion) svgimg.setAttributeNS(null, 'y', (parseInt(y)-33).toString());
          svgimg.setAttributeNS(null, 'visibility', 'visible');
          $($(svg).find('circle.cls-1')[index]).parent().append(svgimg);
          index++;
        }

        var texto = document.createElementNS("http://www.w3.org/2000/svg", "text");
        texto.setAttributeNS(null,"x", '40px');     
        texto.setAttributeNS(null,"y", '30px'); 
        texto.setAttributeNS(null,"font-size","18");
        texto.setAttributeNS(null,"font-family","Arial");

        var textNode = document.createTextNode(refimpresion);
        texto.appendChild(textNode);
        $(svg).find('#Capa_5').prepend(texto);

        // $.LoadingOverlay("hide", true);
        // $('#svg-generado').remove();
        // $('#page-content').append(svg);
        // console.log(svg);

        setTimeout(() => {
          $.LoadingOverlay("hide", true);
          let str = svg[0].outerHTML;
          str = `<?xml version="1.0" encoding="UTF-8"?>\n`+str;
          let svgBlob = new Blob([str], {type:"image/svg+xml;charset=utf-8"});
          let svgUrl = URL.createObjectURL(svgBlob);
          let downloadLink = document.createElement("a");
          downloadLink.href = svgUrl;
          downloadLink.download = item.impresion + '.svg';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }, 300);

        this.BuscarImpresiones();

      }, 'xml');

    });
    
  }
  DesgarcarPagina() {
    for (let item of this.impresiones) {
      this.DescargarEPS(item);
    }
  }
  VerDescargadas() {
    this.paramsimpresiones.archivadas = !this.paramsimpresiones.archivadas;
    this.paramsimpresiones.page_index = 1;
    this.CargarGridImpresiones();
  }

}
