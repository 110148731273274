<ng-container *ngIf="globals && globals.me">
    <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2 || globals.me.id_rol == 3">
        <header class="navbar navbar-inverse navbar-fixed-top navbar-admin">
            <!-- <ul class="nav navbar-nav-custom">
                <li class="hidden-xs animation-fadeInQuick">
                    <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong></a>
                </li>
            </ul> -->
        
            <!-- Right Header Navigation -->
            <ul class="nav navbar-nav-custom pull-right">
                <!-- <li>
                    <a id="btn-nav-irweb"  [routerLink]="['/']" target="_blank">
                        <i class="icofont-eye-alt"></i> Ir a la web
                    </a>
                </li> -->
        
                <!-- User Dropdown -->
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                            <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                        </ng-container>
                        <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                            <img src="assets/icon-user.svg" alt="avatar">
                        </ng-container>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dropdown-header" style="background-color:#F5F5F5;">
                            <strong>{{globals.me.rol}}</strong>
                        </li>
                        <li *ngIf="globals && globals.me && globals.me.id_rol == 0">
                            <a class="cursor" [routerLink]="['/config-app']">
                                <i class="gi gi-settings fa-fw pull-right"></i>
                                Config. App
                            </a>
                        </li>
                        <li>
                            <a class="cursor" [routerLink]="['/mi-perfil']">
                                <i class="fa fa-pencil-square fa-fw pull-right"></i>
                                Mi perfil
                            </a>
                        </li>
                        <li class="divider"><li>
                        <li>
                            <a class="cursor" (click)="Logout()">
                                <i class="fa fa-power-off fa-fw pull-right"></i>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- END User Dropdown -->
            </ul>
            <!-- END Right Header Navigation -->
        </header>
    </ng-container>
    <ng-container *ngIf="globals.me.id_rol == 4">
        <header class="navbar navbar-inverse navbar-fixed-top navbar-admin">
            <!-- <ul class="nav navbar-nav-custom">
                <li class="hidden-xs animation-fadeInQuick">
                    <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong></a>
                </li>
            </ul> -->
        
            <!-- Right Header Navigation -->
            <ul class="nav navbar-nav-custom pull-right">
                <!-- <li>
                    <a id="btn-nav-irweb"  [routerLink]="['/']" target="_blank">
                        <i class="icofont-eye-alt"></i> Ir a la web
                    </a>
                </li> -->
        
                <!-- User Dropdown -->
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                            <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                        </ng-container>
                        <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                            <img src="assets/icon-user.svg" alt="avatar">
                        </ng-container>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dropdown-header" style="background-color:#F5F5F5;">
                            <strong>{{globals.me.rol}}</strong>
                        </li>
                        <li>
                            <a class="cursor" [routerLink]="['/mi-perfil']">
                                <i class="fa fa-pencil-square fa-fw pull-right"></i>
                                Mi perfil
                            </a>
                        </li>
                        <li class="divider"><li>
                        <li>
                            <a class="cursor" (click)="Logout()">
                                <i class="fa fa-power-off fa-fw pull-right"></i>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- END User Dropdown -->
            </ul>
            <!-- END Right Header Navigation -->
        </header>
    </ng-container>
</ng-container>